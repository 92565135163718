import React, { Component } from "react"
import { Link, graphql } from "gatsby"
import AOS from 'aos'
import Layout from "../layouts"
import Flickity from 'react-flickity-component'
import { Helmet } from "react-helmet"

let isDown = false;
let startX;
let scrollLeft;
let loaded = false;

function getRelativeURL(x){
  if (x !== undefined){
    return x.replace(/^.*\/\/[^\/]+/, '')
  }
}

function pad2(number) {
  return (number < 10 ? '0' : '') + number
}

function loadMoreButton(x){
  return(
    <div className="all-work inactive"></div>
  )
}

function myFunction() {
  var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
  var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
  var scrolled = (winScroll / height) * 100;
  document.getElementById("progress-bar").style.width = scrolled + "%";
}

function checkAnimation(i, animatedIn){
  if(i === 0 && animatedIn === false){
    return 0
  }else{
    return 1
  }
}

function checkAnimationDelay(i, animatedIn){
  if(i === 0 && animatedIn === false){
    return 0
  }else if(i === 0 && animatedIn === true){
    return 1;
  }else{
    return 1
  }
}

class Work extends Component {

  constructor(props){

    super(props);

    this.state = {
      animatedIn: false,
      caption: '',
      count: 0,
      overallCount: 0,
      addfitionalInfo: ''
    }

  }

  componentDidMount() {
    AOS.init();

    window.onscroll = function() {
      myFunction();
      AOS.refresh();
    };

    this.setState({
      animatedIn: true,
      filteredWorkGallery: this.props.data.wordpressAcfOptions.options.additional_case_studies[0].category.category_images
    });

    window.onload = () => {
      AOS.refresh();
    }

    this.flkty.on('change', () => {
      let activePanel = document.getElementById("carousel-item"+ this.flkty.selectedIndex)
      let count = activePanel.getAttribute('data-count');
      let overallCount = activePanel.getAttribute('data-overallcount');
      let info = activePanel.getAttribute('data-info');
      let title = activePanel.getAttribute('data-title');
      console.log(activePanel)
      this.setState({
        caption: title,
        count: count,
        overallCount: overallCount,
        addfitionalInfo: info
      });
    })
    this.flkty.on('ready', () => {
      let activePanel = document.getElementById("carousel-item"+ 0)
      let count = activePanel.getAttribute('data-count');
      let overallCount = activePanel.getAttribute('data-overallcount');
      let info = activePanel.getAttribute('data-info');
      let title = activePanel.getAttribute('data-title');
      console.log(activePanel)
      this.setState({
        caption: title,
        count: count,
        overallCount: overallCount,
        addfitionalInfo: info
      });
    })
   
  }

  filterCategory(num){
    var elements = document.getElementsByClassName('category-nav-item');
    for(var i = 0; i < elements.length; i++){
       elements[i].classList.remove('active');
       // console.log(i)
    }

    var element = document.getElementById('category_nav_' + num);
    element.classList.add('active');
    let data = this.props.data.wordpressAcfOptions.options.additional_case_studies[num]
    this.setState({
      animatedIn: true,
      filteredWorkGallery: data.category.category_images
    }, () => {
     this.flkty.resize();
     this.flkty.selectCell(0)
   })
  }

  filterCategoryKeyPress(e, num){
    var charCode = e.charCode || e.keyCode;
     if(charCode == 13) {
      var elements = document.getElementsByClassName('category-nav-item');
      for(var i = 0; i < elements.length; i++){
         elements[i].classList.remove('active');
         // console.log(i)
      }

      var element = document.getElementById('category_nav_' + num);
      element.classList.add('active');
      let data = this.props.data.wordpressAcfOptions.options.additional_case_studies[num]
      this.setState({
        animatedIn: true,
        filteredWorkGallery: data.category.category_images
      }, () => {
       this.flkty.resize();
       this.flkty.selectCell(0)
     })
    }
  }

  mouseDownCatNav(e){
    isDown = true;
    let slider = document.getElementById('category-navigation');
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  }

  mouseLeaveCatNav(){
    isDown = false;
  }

  mouseUpCatNav(){
    isDown = false;
  }

  mouseMove(e){
    if(!isDown) return;
    let slider = document.getElementById('category-navigation');
    e.preventDefault();
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - startX) * 1; //scroll-fast
    slider.scrollLeft = scrollLeft - walk;
    console.log(walk);
  }

  moveElementUp(i){
    var viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    if (viewportWidth > 767) {
      var element = document.getElementById('case_study_card_' + i);
      var elementImage = document.getElementById('card_image_' + i);
      if (element !== null){
        var height = element.offsetHeight
        element.style.top = "calc(100% - " + (height) + "px)";
        elementImage.classList.add('active');
      }
    }
  }

  moveElementDown(i){
    var viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    if (viewportWidth > 767) {
      var element = document.getElementById('case_study_card_' + i);
      var elementImage = document.getElementById('card_image_' + i);
      if (element !== null){
        element.style.top = "calc(100% - 144px)";
        elementImage.classList.remove('active');
      }
    }
  }

  render() {

    const data = this.props.data

    const options = this.props.data.wordpressAcfOptions.options

    const title = this.props.data.allWordpressPage.edges[0].node.acf.title

    let metaData = this.props.data.allWordpressAcfOptions.group[0].edges[0].node.options.meta_data

    if (metaData == null){
      metaData = {
        description: '',
        image_data: {
          url: ''
        }
      }
    }

    var countForGallery = 0;
    let loopClass = ''
    var countCatNumber = 0;
    var countCatCarousel = 0;

    let acfColor = '#F3A738'

    if (typeof window === 'undefined'){
      return (
        <Layout>
        <Helmet script={[{
            type: 'application/ld+json',
            innerHTML: JSON.stringify({
              "@context": "http://schema.org",
              "@type": "WebPage",
              "url": "https://excelinstore.com/",
              "name": "Excel | Moving Retail Forward",
              "author": {
                  "@type": "Organization",
                  "name": "Excel",
                  "url": "https://excelinstore.com/",
                  "address": {
                      "@type": "PostalAddress",
                      "streetAddress": "1021 International Drive",
                      "addressLocality": "Fergus Falls",
                      "addressRegion": "MN",
                      "postalCode": "56537",
                      "addressCountry": "USA"
                  },
                  "contactPoint": {
                      "@type": "ContactPoint",
                      "contactType": "customer support",
                      "telephone": "+1-218-998-3800",
                      "email": "contact@excelinstore.com"
                  }
              },
              "description": "We guide clients through the creation of in-store visual merchandising displays and fixtures.",
              "publisher": {
                  "@type": "Organization",
                  "name": "Excel",
                  "url": "https://excelinstore.com/",
                  "address": {
                      "@type": "PostalAddress",
                      "streetAddress": "1021 International Drive",
                      "addressLocality": "Fergus Falls",
                      "addressRegion": "MN",
                      "postalCode": "56537",
                      "addressCountry": "USA"
                  }
              }
            })
          }]}>
          <html lang="en" />
          <meta charSet="utf-8" />
          <title>Excel | Our Work</title>
          <meta name="description" content={metaData.description} />
          <meta property="og:title" content={"Excel | Our Work"}  />
          <meta property="og:type" content="WebSite" />
          <meta property="og:url" content="https://excelinstore.com/" />
          <meta property="og:image" content={metaData.image_data.url} />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:description" content={metaData.description} />
          <meta property="og:site_name" content="Excel" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={"Excel | Our Work" } />
          <meta name="twitter:description" content={metaData.description} />
          <meta name="twitter:image" content={metaData.image_data.url} />
          
        </Helmet>
        </Layout>
        )
    }else{
      var called = false
      window.addEventListener('mousedown', function() {
        if (called === false){
            let elem = document.getElementById('excel-global-header-inner')
            elem.classList.add('using-mouse');
            called = true
        }
      });

      return(
        <Layout>
        <Helmet script={[{
            type: 'application/ld+json',
            innerHTML: JSON.stringify({
              "@context": "http://schema.org",
              "@type": "WebPage",
              "url": "https://excelinstore.com/",
              "name": "Excel | Moving Retail Forward",
              "author": {
                  "@type": "Organization",
                  "name": "Excel",
                  "url": "https://excelinstore.com/",
                  "address": {
                      "@type": "PostalAddress",
                      "streetAddress": "1021 International Drive",
                      "addressLocality": "Fergus Falls",
                      "addressRegion": "MN",
                      "postalCode": "56537",
                      "addressCountry": "USA"
                  },
                  "contactPoint": {
                      "@type": "ContactPoint",
                      "contactType": "customer support",
                      "telephone": "+1-218-998-3800",
                      "email": "contact@excelinstore.com"
                  }
              },
              "description": "We guide clients through the creation of in-store visual merchandising displays and fixtures.",
              "publisher": {
                  "@type": "Organization",
                  "name": "Excel",
                  "url": "https://excelinstore.com/",
                  "address": {
                      "@type": "PostalAddress",
                      "streetAddress": "1021 International Drive",
                      "addressLocality": "Fergus Falls",
                      "addressRegion": "MN",
                      "postalCode": "56537",
                      "addressCountry": "USA"
                  }
              }
            })
          }]}>
          <html lang="en" />
          <meta charSet="utf-8" />
          <title>Excel | Our Work</title>
          <meta name="description" content={metaData.description} />
          <meta property="og:title" content={"Excel | Our Work"}  />
          <meta property="og:type" content="WebSite" />
          <meta property="og:url" content="https://excelinstore.com/" />
          <meta property="og:image" content={metaData.image_data.url} />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:description" content={metaData.description} />
          <meta property="og:site_name" content="Excel" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={"Excel | Our Work" } />
          <meta name="twitter:description" content={metaData.description} />
          <meta name="twitter:image" content={metaData.image_data.url} />
          
        </Helmet>
        <div className="progress"><div id="progress-bar" style={{backgroundColor: acfColor}}></div></div>
        <div className="hero--wrapper">  
          <div className="hero">
            <h1 className="micro" dangerouslySetInnerHTML={{__html: 'Our Work'}} data-aos="fade-up" data-aos-delay="400" data-aos-anchor=".hero"/>
            <div className="title h2" data-aos="fade-up" data-aos-delay="800" data-aos-anchor=".hero" dangerouslySetInnerHTML={{__html: title}}></div>
          </div>
        </div>
        <section className="waypoint our_work">
          <div>
          { this.props.data.allWordpressWpWork !== null &&
            <div>
            { this.props.data.allWordpressWpWork.nodes.map((elem, i) => (
              <div key={"work_" + i} data-aos="fade-up" data-aos-anchor={ checkAnimation(i, this.state.animatedIn) ? "" : ".hero"} data-aos-delay={checkAnimationDelay(i, this.state.animatedIn) ? "0" : "1200"}>
                <Link to={getRelativeURL(elem.link)} key={"case_study_" + i} className="case_study_link" onFocus={() => this.moveElementUp(i)} onBlur={ () => this.moveElementDown(i)} onMouseEnter={() => this.moveElementUp(i)} onMouseLeave={ () => this.moveElementDown(i)}>
                  { elem.acf.case_study !== null &&
                    elem.acf.case_study.service_hero_images !== null &&
                    elem.acf.case_study.service_hero_images[0].image !== null &&
                    <div className="cs-outer">
                     { elem.acf.case_study !== null &&
                      <div className="cs-inner">
                        { elem.acf.case_study.service_hero_images.length > 0 &&
                          elem.acf.case_study.service_hero_images !== null && 
                          <div id={"card_image_" + i} className="cs-inner--image" style={{'backgroundImage': "url(" + elem.acf.case_study.service_hero_images[0].image.url + ")"}}>
                            <img
                              src={elem.acf.case_study.service_hero_images[0].image.url}
                              alt={elem.acf.case_study.service_hero_images[0].image.alt} />
                          </div>
                        }
                        { elem.acf.case_study.service_hero_images.length > 1 && 
                          <div className="js-cs-inner">
                          { elem.acf.case_study.service_hero_images.map((image, i) => (
                            <div className="cs-inner--image" style={{'backgroundImage': image.image.url}}>
                              <img
                                src={image.image.url}
                                alt={image.image.alt} /> 
                            </div>
                            ))
                          }
                          </div>
                        }
                        <div className="cs--pull-tab" id={"case_study_card_" + i}>
                          { elem.title !== "" &&
                            <h3>{elem.title}</h3>
                          }
                          { elem.acf.case_study.service_preview_description !== "" &&
                            <div className="cs--copy">{elem.acf.case_study.service_preview_description}</div>
                          }
                          <span className="cta no-hover">View Project<span><span></span></span></span>
                        </div>
                      </div>
                     }
                    </div>
                  }
                  </Link>
                </div>
               ))}
              </div>
            }
            </div>
        </section>
        <div className="additional-work">
            <div className="inner">
              <div className="title" data-aos="fade-up">Gallery</div>
              <div className="header" data-aos="fade-up">Additional Work</div>
              { options.additional_case_studies.length > 0 &&
                <div data-aos="fade-up" className="category-navigation" id="category-navigation" onMouseDown={(e) => this.mouseDownCatNav(e)} onMouseLeave={ () => this.mouseLeaveCatNav()} onMouseUp={ () => this.mouseUpCatNav()} onMouseMove={ (e) => this.mouseMove(e)}>
                  { options.additional_case_studies.map((cat, i) => {
                    if (countCatNumber == 0){
                      loopClass = 'active'
                    }else{
                      loopClass = '';
                    }
                    countCatNumber++
                    i++
                    return(
                      <div className={"category-nav-item " + loopClass} key={"category_nav_" + (i-1) } id={"category_nav_" + (i-1) } onClick={() => this.filterCategory(i-1)} onKeyPress={(e) => this.filterCategoryKeyPress(e, i-1)} tabIndex="0">
                        { cat.category.category_title }
                      </div>
                    )
                    
                  })}
                </div>
              }
            </div>
            <div data-aos="fade-up">
              { options.additional_case_studies.length > 0 &&
                <Flickity flickityRef={c => this.flkty = c} className="category carousel">
                  { typeof this.state.filteredWorkGallery !== 'undefined' &&
                    this.state.filteredWorkGallery.map((img, i) => {
                    countForGallery  += 1;
                    var overallCount = this.state.filteredWorkGallery.length;
                    return(
                      <div className="carousel-item" key={'carousel-item' + i} id={'carousel-item' + i} data-count={countForGallery} data-overallcount={overallCount} data-info={img.additional_information_ci} data-title={img.caption}>
                        { img.image.url !== "" &&
                          <div className="image" style={{'backgroundImage': "url(" + img.image.url + ")"}}>
                            <img src={img.image.url} />
                          
                          </div>
                        }
                      </div>
                    )
                  })}
                </Flickity>
              }
            </div>
            <div className="caption">
              <div className="title">{this.state.caption}</div><span>{pad2(this.state.count)}/{pad2(this.state.overallCount)}</span>
              <div className="additional" dangerouslySetInnerHTML={{__html: this.state.addfitionalInfo}}></div>
            </div>
          </div>
      </Layout>
    )
}
  }
}

export default Work

// Set here the ID of the home page.
export const pageQuery = graphql`
  query {
    allWordpressAcfOptions {
      group(field: id) {
        edges {
          node {
            options {
              meta_data_by_page {
                our_work {
                  description
                  image_data {
                    alt
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
    allWordpressPage(filter: {template: {eq: "our-work.php"}}) {
      edges {
        node {
          acf {
            title
          }
        }
      }
    }
    wordpressAcfOptions {
      options {
        additional_case_studies {
          category {
            category_images {
              image {
                alt
                url
              }
              caption
              additional_information_ci
            }
            category_title
          }
        }
      }
    }
    allWordpressWpWork(sort: {order: ASC, fields: menu_order}, filter: {status: {eq: "publish"}, slug: {ne: "do-not-delete"}}) {
      nodes {
        acf {
          case_study {
            featured_on_homepage
            service_hero_images {
              image {
                alt
                url
                title
              }
            }
            service_preview_description
          }
        }
        link
        title
      }
    }
  }
`
